<template>
  <div>
    <!-- Inputs table -->
    <div class="mb-2" v-for="input in inputsToPatch" v-bind:key="input.inputsStock">

      <!-- input stock infos -->
      <b-row class="m-0" no-gutters>
        <b-col
          cols="7"
          class="text-infos bg-white d-flex align-items-start py-2 pl-2 pr-1"
          @change="checkUnits(input)"
        >
          <s v-if="!isInputInInputform(input)">
            {{ getRootInputField(input.inputsStock, "inputsStock_type") + " - " + getRootInputField(input.inputsStock, "inputsStock_name") }}
          </s>
          <span v-else>
            {{ getRootInputField(input.inputsStock, "inputsStock_type") + " - " + getRootInputField(input.inputsStock, "inputsStock_name") }}
          </span>
        </b-col>
        <b-col cols="3" class="text-infos bg-white d-flex align-items-start pr-2">
          <div
            v-if="input.errQuantity"
            class="text-activity-notes error-quantity-inputs"
          >{{ input.errMessage }}</div>
          <!--
            * grey text if it is a cropfield input and the dose is same than default
          -->
          <NumericInput
            ref="focused"
            inputRef="input"
            name="input-stock"
            :numberOfDigits="5"
            :value="parseFloat(input.dose)"
            @input="dose => {input.dose = dose}"
            @blur="checkRatio(input)"
            placeholder="Dose"
            :class="{ 'text-middle-grey': input.dose == getRootInputField(input.inputsStock, 'dose') }"
            :required="true"
            v-b-tooltip.hover
            :title="$t('tours.details.cropfield_input_hover')"
          />
        </b-col>
        <b-col cols="2" class="text-infos bg-white pt-2 pr-2 text-right">
          {{ getDoseUnitLabel(input.doseUnit.find(d => d.id == input.unit).id) }}
        </b-col>
      </b-row>

      <!-- input stock volumes details -->
      <b-row class="px-2 m-0 br-top-0 bg-grey text-infos" v-if="showVolumes" no-gutters>
        <b-col cols="5" class="text-dark-grey">{{ $t("components.Inputs.volume-to-use") }}</b-col>
        <b-col cols="7" class="text-right">
          ({{ input.dose * getCoefToReferenceUnit(input) }} x {{ currentCropfieldSurface }})
          = {{ round(input.dose * getCoefToReferenceUnit(input) * currentCropfieldSurface, 2) }} {{ getRelatedDoseUnit(input.doseUnit.find(d => d.id == input.unit).id) }}
        </b-col>
      </b-row>
    </div>

    <!-- input stock mixture volume -->
    <div class="bg-grey text-infos br-1" v-if="showMixtureVolumes">
      <b-row class="px-0 m-0 mb-2" align-v="center" no-gutters>
        <b-col cols="7" class="text-dark-grey">{{ $t("components.Inputs.mixture-volume") }}</b-col>
        <b-col cols="3" class="text-right d-flex align-items-start">
          <NumericInput
            inputRef="input"
            :value="mixtureVolume"
            @input="i => {mixtureVolume = i}"
            :numberOfDigits="2"
            :required="true"
            class="width-input-quantity"
            :class="{ 'text-middle-grey': mixtureVolume == rootMixtureVolume }"
          />
        </b-col>
        <b-col cols="2" class="text-right">
          {{ getDoseReferenceUnit().unit }}
        </b-col>
      </b-row>
      <b-row class="px-0 m-0" align-v="center" no-gutters>
        <b-col cols="6" class="text-dark-grey">{{ $t("components.Inputs.total-volume") }}</b-col>
        <b-col cols="6" class="text-right font-weight-bold">({{ mixtureVolume }} x {{ currentCropfieldSurface }}) = {{ round(mixtureVolume * currentCropfieldSurface, 2) }} {{ getRelatedDoseUnit(getDoseReferenceUnit().unit) }}</b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import Vue from "vue";

import { askConfirm } from "@/utils/commons";

import delIcon from "@/assets/images/delete.svg";
import ActivityModule from "@/store/modules/activity";
import { SET_STATE_INPUT_TO_PATCH } from "@/store/modules/activity";
import { mapGetters } from "vuex";
import DetailUpdateMixin from "@/mixins/DetailUpdateMixin";
import Dropdown from "@/components/activity/edit/Dropdown.vue";
import LoadFormPartMixin from "@/mixins/LoadFormPartMixin";
import NumericInput from "@/components/commons/NumericInput.vue";

import { EventBus } from "@/eventBus.js";

export default {
  name: "edit-cropfield-inputs",
  mixins: [DetailUpdateMixin, LoadFormPartMixin],
  props: {
    useForm: {
      type: String,
      require: true,
    },
    inputFormPath: {
      type: String
    },
    showVolumes: {
      type: Boolean,
      default: false
    },
    /**
     * not empty if the inputs are related to a cropfield
     */
    cropfieldId: {
      type: Number
    }
  },
  data() {
    return {
      inputsToPatchClone: [],
      inputsUpdated: [],
      page: this.useForm,
      icon: delIcon,
      inputSelected: "",
      inputsToPatch: [],
      units: [],
      selectedDoseUnit: {},
      loadComponent: false,
      mixtureVolume: 0,
      mixtureThresholdVolume: 50,
      // previous dose values of inputs
      // used to trigger or not the 'dose-apply-ratio' message
      // see in method 'checkRatio'
      // inputId <-> dose dictionnary
      previousDoses: {}

    };
  },
  components: {
    Dropdown,
    NumericInput,
  },
	beforeDestroy() {
    EventBus.$off("onInputAdded", this.addCropfieldInput)
    EventBus.$off("onInputChanged", this.updateCropfieldInput)
    EventBus.$off("onInputDeleted", this.onDelete)
    EventBus.$off("onMixtureVolumeChanged", this.updateCropfieldMixtureVolume)
  },
  created() {
    this.init();

		// listen for the events fired from the root input list
		// for added, changed and deleted inputs
		EventBus.$on("onInputAdded", this.addCropfieldInput)
		EventBus.$on("onInputChanged", this.updateCropfieldInput)
		EventBus.$on("onInputDeleted", this.onDelete)
    EventBus.$on("onMixtureVolumeChanged", this.updateCropfieldMixtureVolume)
  },
  methods: {
    round: _.round,
    /**
     * this should be refactored
     */
    init: async function() {
      this.loadComponent = false;

      if (["tour.edit", "tour.duplicate", "tour.new.edit"].includes(this.$route.name)) {
        this.inputsToPatch = [];

        this.storedInputs.map(input => {
          let doses = this.getInputAttribut(input.inputsStock, "doseUnit", "id");
          doses = _.clone(doses);
          const doseUnitId = this.getRootInputField(input.inputsStock, "doseUnit");
          let filteredDoses = doses.filter(e => {
            if(e.id == doseUnitId) return e;
          })

          this.inputsToPatch.push({
            inputsStock: input.inputsStock,
            dose: input.dose,
            doseUnit: filteredDoses,
            unit: this.getRootInputField(input.inputsStock, "unit") || this.getRootInputField(input.inputsStock, "doseUnit"),
            errQuantity: false,
            errMessage: "",
            inputsStock_name: input.inputsStock_name,
            inputsStock_type: input.inputsStock_type,
          });

          // store dose value for further checks
          Vue.set(this.previousDoses, input.inputsStock, input.dose);
        });
      }
      this.loadComponent = true;

      this.mixtureVolume = this.currentCropfield.mixtureVolume || this.rootMixtureVolume || 0;
    },

    /**
     * Permet de vérifier si un intrant est dans le formulaire inputForm
     */
    isInputInInputform(input) {
      return this.inputForm.inputsStock?.filter(e => e.id == input.inputsStock).length != 0;
    },

    /**
     * Delete inputs into inputsToPatch.
     */
    onDelete(id) {
      let idInput = id;
      let val = this.inputsToPatch.filter(function(elem) {
        if (elem.inputsStock == idInput) return elem;
      });

      const filter = this.inputsToPatch.indexOf(val[0]);
      if (filter > -1) this.inputsToPatch.splice(filter, 1);
    },

    /**
     * For cropfield inputs
     * When a dose is changed and there are several inputs
     * than suggests the user to apply same ratio on others inputs
     */
    async checkRatio(input) {
			// buffer previous dose
      const previousDose = this.previousDoses[input.inputsStock]

      // store dose value for further checks
      Vue.set(this.previousDoses, input.inputsStock, input.dose)

      // if value did not change, return
      if(previousDose == input.dose) return

      // if missing value, stop
      if(!input.dose || !previousDose) return

      // stop if only one input and no mixture volume
      if(this.allInputsToPatch.length <= 1 && !this.mixtureVolume) return

      // compute ratio as decimal, like 0.75 for 75%
      const ratio = _.round(input.dose / previousDose, 2)

      // compute ratio in percent for display in modal
      const ratioPercent = _.round((input.dose / previousDose - 1) * 100);

      // used to keep input list opened when modal displayed
      this.$emit("modalOpen")

      // ask the user
      const answer = await askConfirm(this.$t("components.EditInputs.dose-apply-ratio").replace("{{x}}", ratioPercent));

      this.$emit("modalClose")

      // if yes
      if(answer) {
        // apply same ratio on others inputs
        const inputs = this.allInputsToPatch;
        for(let i = 0; i < inputs.length; i++) {
          // ignore if it is current inputstock
          if(input.inputsStock === inputs[i].inputsStock) continue

          const previousDose = this.previousDoses[inputs[i].inputsStock]
          const newDose = _.round(ratio * previousDose, 4);
          inputs[i]["dose"] = newDose

          // store dose value for further checks
          Vue.set(this.previousDoses, inputs[i].inputsStock, newDose)
        }

        // apply same ratio on mixture volume
        if (this.mixtureVolume) this.mixtureVolume = _.round(ratio * this.mixtureVolume, 2)
      }
    },

    /**
     * Description: Confirme le chargement des données de ce composant.
     * @return {Boolean} Component loaded.
     */
    validateForm: function() {
      this.isValid = true;
      return this.loadComponent;
    },

    /**
     * Description: Renvoie l'attribut d'un input selon le nom de l'attribut de comparaison renseigné
     * (l'ID par defaut) et la valeur de celui-ci.
     * @param {}            attrValue La valeur de l'attribut de l'input que l'on connaît.
     * @param {String}      attrResearched Le nom de l'attribut que l'on recherche.
     * @param {String}      attrCmp Le nom de l'attribut par lequel comparer la value de attrValue
     *                          (l'ID par défaut).
     * @return {}           La valeur de retour dépend de l'attribut recherché.
     */
    getInputAttribut(attrValue, attrResearched, attrCmp = "id") {
      let input = this.inputForm.inputsStock.filter(function(elem) {
        if (elem[attrCmp] == attrValue) return elem;
      });
      if(typeof input[0] === 'undefined') return [];
      return input[0][attrResearched];
    },

    checkUnits(input) {
      this.units = getInputAttribut(input.inputsStock, "doseUnit");
    },
    getRelatedDoseUnit(unit) {
      return this.doseUnits.find(d => d.id == unit)?.relatedUnit_unit
    },

    /**
     * Retourne l'unité de réference des doses des liquides
     */
    getDoseReferenceUnit() {
      let ref_dose = null;
      this.doseUnits.map(d =>{
        if(d.isReference == true && d.forDose == true && d.form == "L") {
          ref_dose = d;
        }
      });
      return ref_dose
    },

    /**
     * Retourne le label d'une unité à partir de son id
     */
    getDoseUnitLabel(id) {
      let unit = this.doseUnits.find(d => d.id == id);
      if (unit) return unit.unit;
      return "";
    },

    // add a input in cropfield input list
    async addCropfieldInput(input) {
      const cropfield = _.clone(this.currentCropfield)

      this.setCropfieldField("inputs", [
        ... (cropfield.inputs || []),
        input
      ])

      // store dose value for further checks
      Vue.set(this.previousDoses, input.inputsStock, input.dose);

      await this.init();
    },

    // update an input in cropfield input list
    async updateCropfieldInput(input, ratio) {
      // get cropfield
      const cropfield = this.currentCropfield

      if (!cropfield.inputs) {
        cropfield.inputs = [];
        cropfield.inputs.push(...this.rootInputs);
      }

      // index of the input in list
      const index = cropfield.inputs.findIndex(e => e.inputsStock == input.inputsStock)

      const inputs = cropfield.inputs

      let newDose;
      if (ratio != null){
        newDose = _.round(inputs[index].dose * ratio, 4)
      } else if(!inputs[index].dose || inputs[index].dose == this.getRootInputField(inputs[index].inputsStock, "dose")) {
        newDose = input.dose
      } else {
        newDose = inputs[index].dose
      }
      const newUnit = input.unit

      inputs[index] = {
        ... inputs[index],
        dose: newDose,
        unit: newUnit,
        doseUnit: newUnit,
      }

      await this.setCropfieldField("inputs", inputs)

      // store dose value for further checks
      Vue.set(this.previousDoses, inputs[index].inputsStock, newDose)

      await this.init()
    },

    getCoefToReferenceUnit(input) {
      const doseUnit = input.doseUnit ? input.doseUnit.find(d => d.id === input.unit) : null;
      return doseUnit ? doseUnit.coefToReferenceUnit : 1;  // Coefficient à utiliser dans le calcul du total
    },
    // update a mixture volume in cropfield
    updateCropfieldMixtureVolume(value) {
      const mixtureVolume = this.currentCropfield.mixtureVolume;
      if(!mixtureVolume || mixtureVolume == this.rootMixtureVolume) {
        this.mixtureVolume = value
      }
    },

    /**
     * Update a field of a given cropfield
     */
    async setCropfieldField(field, value) {
      // clone current cropfield
      const cropfield = _.clone(this.currentCropfield)

      // clone all cropfields
      const cropfields = _.clone(this.cropfields)

      // index of cropfield in list
      const index = this.cropfields.findIndex(c => c.cropField == this.cropfieldId)

      // update value
      cropfield[field] = value
      cropfields[index] = cropfield

      // set updated list
      await this.$store.dispatch(this.storeEditAction, {
        cropFields: cropfields
      });
    },
    /**
     * an helper to get the value of a field on the root inputs
     * because cropfield inputs do not have always all the data need for display
     */
    getRootInputField(inputId, field) {
      return this.rootInputs.find(i => i.inputsStock == inputId)?.[field]
    },

  },
  beforeCreate() {
    const store = this.$store;
    if (store && store.state) {
      if (!store.state["activity"]) {
        store.registerModule("activity", ActivityModule);
      }
    }
  },
  computed: {
    ...mapGetters({
      currentActivity: "activity/currentEntry",
      inputsMustToBeClean: "activity/inputsMustToBeClean",
      doseUnits: "doseUnits"
    }),
    inputForm() {
      return this.$store.getters[this.inputFormPath]
    },
    /**
     * either cropfieldInputs or rootInputs
     */
    storedInputs() {
			return this.cropfieldInputs.length > 0 && this.cropfieldInputs
			|| this.rootInputs.length > 0 && this.rootInputs
			|| []
    },
    /**
     * inputs related to the cropfieldId
     */
    cropfieldInputs() {
      const entryInputs = this.currentEntry.cropFields?.find(c => c.cropField == this.cropfieldId)?.inputs
      const editedEntryInputs = this.currentEditedEntry.cropFields?.find(c => c.cropField == this.cropfieldId)?.inputs

      return editedEntryInputs && editedEntryInputs.length > 0 && editedEntryInputs || entryInputs || []
    },
    /**
     * Renvoie tout les inputs à patcher.
     * Vérifie si il y a eu un changement de type d'activité et s'il faut donc
     * vider le contenu du tableau 'inputsToPatch'.
     */
     allInputsToPatch() {
      if (this.inputsMustToBeClean) {
        this.inputsToPatch = [];
        this.$store.commit("activity/" + SET_STATE_INPUT_TO_PATCH, false);
      }
      return this.inputsToPatch;
    },

    /**
     * inputs by default, at the root of the entry
     */
    rootInputs() {
      const entryInputs = this.currentEntry.inputs
      const editedEntryInputs = this.currentEditedEntry.inputs
      return editedEntryInputs && editedEntryInputs.length > 0 && editedEntryInputs || entryInputs || []
    },
    rootMixtureVolume() {
      return this.currentEditedEntry.mixtureVolume || this.currentEntry.mixtureVolume || 0
    },
    cropfields() {
      return this.currentEditedEntry.cropFields || this.currentEntry.cropFields
    },
    currentCropfield() {
      return this.cropfields.find(e => e.cropField == this.cropfieldId)
    },
    currentCropfieldSurface() {
      return parseFloat(this.currentCropfield.surfaceToWork || this.currentCropfield.cropField_surface)
    },
    /**
     * Renvoie un array pris de this.inputForm.inputsStock + surchargé avec un attribut
     * category_inputName qui va être util dans le DropDown
     */
    getInputsUpdated() {
      this.inputsUpdated.length = 0;
      if(this.inputForm.inputsStock != undefined)
      {
        this.inputForm.productTypes.map(productType => {
          this.inputsUpdated.push({
            id: productType.id + "group",
            designation: productType.designation,
            group: true,
          });
          this.inputForm.inputsStock.map(input => {
            if(input.productType === productType.id){
              this.inputsUpdated.push({
                ...input,
                designation: input.inputName
              });
            }
          });
        });
        // this.inputsUpdated = this.inputForm.inputsStock.map(obj => ({
        //   ...obj, category_inputName: obj.category + " - " + obj.inputName,
        //   }));
      }
      return this.inputsUpdated;
    },
    totalDose() {
      let somme = 0;
      this.allInputsToPatch.map(input =>{
        if (input.unit == this.getDoseReferenceUnit().id)
          somme = somme + parseFloat(input.dose);
      });
      return somme;
    },
    /* when do we show mixture (bouillie) volumes ? */
    showMixtureVolumes() {
      return this.showVolumes // the showVolumes mode is activated
        && (this.currentEditedEntry.activityType_code || this.currentEntry.activityType_code) == "pulve" // we are in pulve family
        && this.allInputsToPatch.length > 0 // there are inputs
        && (this.totalDose < this.mixtureThresholdVolume) // we are below mixture threshold
    }
  },
  watch: {
    inputsToPatch: {
      deep: true,
      handler: async function() {
        this.inputsToPatchClone = JSON.parse(JSON.stringify(this.inputsToPatch));
        this.inputsToPatchClone.map(e=>{
          e.doseUnit = e.unit;
        })
        if (this.inputsToPatchClone.length != 0) this.$store.dispatch(this.storeEditAction, { inputConfirmed: true });

        // cropfield input
        // On enregistre les intrants sur la parcelle, uniquement si ce sont des doses spécifiques
        let isSpecific = false;
        let testInput = null;
        this.inputsToPatchClone.forEach(cf_input => {
          testInput = this.rootInputs?.find(input => input.inputsStock == cf_input.inputsStock && input.dose != cf_input.dose)
          if (testInput) {
            isSpecific = true;
          }
        })
        if (isSpecific) this.setCropfieldField("inputs", this.inputsToPatchClone)
        else this.setCropfieldField("inputs", [])
      },
    },

    mixtureVolume(value) {
      // On enregistre le volume de bouillie sur la parcelle, uniquement s'il est spécifique
      if (parseInt(value) != parseInt(this.rootMixtureVolume))
        this.setCropfieldField("mixtureVolume", parseInt(value))
    }
  },
};
</script>

<style lang="scss" scoped>
table {
  border-collapse: separate;
  border-spacing: 3px 3px;
  margin-bottom: 4px;
}

button {
  //   background-color: $danger-color;
  font-size: 0.8em;
  font-weight: bold;

  img {
    width: 1.5em;
  }
}

/**
 * Parameter error quantity inputs.
 */
.error-quantity-inputs {
  // text-align: center;
  color: $danger-color;
}
</style>
