<template>
  <div>
    <CropDropdown
      ref="dropdown"
      label="name"
      :options="crops"
      @updateRow="onInput"
      :value="storeValue"
      :clearable="true"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";

import DetailUpdateMixin from "@/mixins/DetailUpdateMixin";

import CropDropdown from '@/components/commons/CropDropdown.vue';

import { EventBus } from "@/eventBus.js";

export default {
  mixins: [DetailUpdateMixin],
  components: {
    CropDropdown
  },
  data() {
    return {
      localValue: null
    }
  },
  props: {
    saveInStore: {
      type: Boolean,
      default: true
    },
    filterCropfields: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      crops: "crop/crops",
      currentStockStore: "stockStores/currentStockStore"
    }),
    cropsOptions() {
      return this.crops || []
    },
    storeValue() {
      return this.cropsOptions.find(c => c.id == (this.currentEditedEntry.crop || this.currentEntry.crop))
    },
    value() {
      if(this.saveInStore || !this.localValue) {
        return this.storeValue
      } else {
        return this.cropsOptions.find(c => c.id == this.localValue)
      }
    }
    // todo: should not be here
    // disabled() {
    //   // if there is any cropfield, disable crop change
    //   if(this.currentEditedEntry.cropFields) return this.currentEditedEntry.cropFields.length > 0
    //   return this.currentEntry.cropFields && this.currentEntry.cropFields.length > 0
    // }
  },
  async mounted() {
    await this.$store.dispatch("crop/fetchCrops");
    this.$refs["dropdown"].setValue(this.storeValue)

    if(this.filterCropfields) {
      this.filterCropfieldsInStore()
    }
  },
  methods: {
    onInput(rowId, { crop, crop_name }) {
      if(this.saveInStore)
        this.$store.dispatch(this.storeEditAction, {
          crop: crop,
        });

      this.localValue = crop

      if(this.filterCropfields) {
        this.filterCropfieldsInStore()
      }
    },
    /**
     * If the prop 'filterCropfields' is set to true
     * this component will filter crop value in cropfields store
     */
    async filterCropfieldsInStore() {
      // update the cropfields crop filter value
      await this.setFilter({filterName: "crop", elementId: this.value?.id})
      // fetch cropfields
      await this.fetchCropfields({stockStore: this.currentStockStore?.id, withGeometries: true});
      // plot cropfields
      EventBus.$emit("plotFields", true)

      this.$emit("change", this.value)
    },
    ...mapActions({
      fetchCropfields: "cropfield/fetchEntries"
    }),
    ...mapMutations({
      setFilter: "cropfield/setFilter",
    })
  }
};
</script>

<style lang="scss" scoped>
</style>
