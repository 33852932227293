/*
 * 
 * create reusable feature sets for Vue components. 
 * By declaring them via the mixins option, all mixin o
 * ptions will be merged (merge) with the component options.
 *
 */
export default {
  data() {
    return {
      isValid: false,
      dataToLoad: {},
    };
  },
  inject: ["loadedData"],
  mounted() {
    this.dataToLoad = this.loadedData.dataToLoad;
    this.dataToLoad.formParts.push(this);
  },
  computed: {},
  methods: {},
};
