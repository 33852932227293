<template>
  <div class="position-relative">
    <!--
      DETAIL SIDEBAR CONTENT
    -->
    <!-- Displays the title and the cropfields number -->
    <h4 class="subtitle-form">
      {{ (entry.cropFields && entry.cropFields.length ? entry.cropFields.length + " " : "") + $t("commons.list.cropfields") }}
    </h4>
    <!-- Display total area -->
    <div v-if="totalSurface" class="total-area text-dark-grey">
      {{ $t("components.EditCropfields.total-area") }}: {{ totalSurface }} ha
    </div>
    <!-- List of cropfields -->
    <div
      @mouseover="detailCropfield = index"
      @mouseleave="detailCropfield = null"
      @click="onClickCropfieldCard(index)"
      v-for="(cropfield, index) in entry.cropFields"
      :key="index"
      :class="['mb-2 cropfield-card', { 'hovered': (keepDropdownOpen == index || detailCropfield == index) }]">
      <b-row class="m-0" no-gutters>
        <b-col cols="12" :class="['text-infos d-flex justify-content-between align-items-start p-2',
                            { 'specific-cropfield': isCropfieldSpecific(cropfield), 'bg-white': !isCropfieldSpecific(cropfield)}]"
        >
          <div class="mr-4">
            <b>{{ cropfield.cropField_name }} ({{ cropfield.surfaceToWork || cropfield.cropField_surface }} ha)</b><br>
            {{ cropfield.farm_name }} · {{ cropfield.crop_name }}
          </div>
          <div class="position-absolute top-1 right-2">
            <img v-if="keepDropdownOpen == index" :src="chevronUpIcon" width="24px" height="24px" alt="icon action" />
            <img v-else :src="chevronDownIcon" alt="icon action" />
          </div>
          <div v-if="detailCropfield == index" class="mt-3">
            <b-button @click="deleteCropfield(cropfield.cropField, true)" class="m-0 p-1 button-icon button-transparent">
              <img :src="delIcon" class="mx-0" alt="icon delete action" />
            </b-button>
          </div>
        </b-col>
      </b-row>
      <div class="cropfield-card-details-scroll">
        <b-row class="px-2 m-0 br-top-0 bg-grey" no-gutters>
          <b-col cols="8" class="text-dark-grey text-infos">{{ $t("components.EditCropfields.surface-to-work") }}</b-col>
          <b-col cols="4" class="text-right text-infos">
            <div class="surface-input">
              <NumericInput
                inputRef="input"
                :numberOfDigits="4"
                :value="parseFloat(cropfield.surfaceToWork || cropfield.cropField_surface)"
                @input="(surface) => setCropfieldSurface(cropfield.cropField, surface.toString())"
                :required="true"
              />
            </div>
          </b-col>
        </b-row>
        <EditCropfieldInputs
          v-if="defineSpecificDoses"
          :class="{ 'mt-2': cropfield.inputs && cropfield.inputs.length > 0 }"
          storeEditAction="tour/setEditedEntryValue"
          storeReadGetter="tour/currentEntry"
          storeEditedGetter="tour/editedEntry"
          inputFormPath="tour/inputForm"
          :showVolumes="true"
          :cropfieldId="cropfield.cropField"
          @modalOpen="keepDropdownOpen = index"
          @modalClose="onInputModalClose"
        />
      </div>
    </div>
    <!-- Case à cocher activant la définition des doses spécifiques -->
    <div v-if="hasMoreThanOneCropfield && hasInputs">
      <b-row>
      <b-col cols="12">
        <b-form-checkbox
          class="text-base"
          v-model="defineSpecificDoses"
        >
          <div class="my-1">{{ $t("tours.details.define_specific_doses") }}</div>
        </b-form-checkbox>
      </b-col>
    </b-row>
    </div>

    <!--
      MODAL CONTENT
    -->
    <b-modal
      id="cropfield-picker-modal"
      ref="modal"
      dialog-class="modal-no-max-width"
      :no-close-on-backdrop="!closable"
      :no-close-on-esc="!closable"
      size="xl"
      hide-header
      hide-footer
      ignore-enforce-focus-selector="header, .app-page-title, .details"
    >
      <a href="javascript:void(0)" v-if="closable" @click="$refs['modal'].hide()" class="close-button"></a>
      <b-row no-gutters>
        <b-col cols="12" class="px-2">
          <h2>
            {{ $t("components.EditCropfields.title") }}
            <span> ({{ $t("components.EditCropfields.group-selection-help") }}) </span>
          </h2>
        </b-col>
        <b-col cols="8">
          <!-- Map -->
          <CropfieldsMap
            class="map"
            ref="map"
            :menuBar="false"
            :searchBar="false"
            :routing="false"
            :selectionMultipleByDefault="true"
            :deleteTrashIcon="true"
            :clearSelectionOnLoad="true"
            :layers="['osm', 'secondCrop']"
            :fullScreenButton="false"
            :isOnCropfieldView="false"
            :enableFreeHand="true"
            @mapready="onMapReady"
          />
        </b-col>
        <b-col cols="4">
          <div class="search-panel d-flex flex-column p-2">
            <!-- Crop selection -->
            <h3 class="mb-2">{{ $t('commons.list.crops') }}</h3>
            <EditCrop
              storeEditAction="tour/setEditedEntryValue"
              storeReadGetter="tour/currentEntry"
              storeEditedGetter="tour/editedEntry"
              inputFormPath="tour/inputForm"
              :filterCropfields="true"
              :saveInStore="false"
              @change="onSelectCrop"
            />

            <!-- Search form -->
            <h3 class="mt-3 mb-2">{{ $t('commons.list.cropfields') }}</h3>

            <b-input-group size="sm" class="mb-4 input-group-border">
              <b-input-group-prepend>
                <img :src="searchIcon" class="w-100" alt="filter search" />
              </b-input-group-prepend>
              <b-form-input
                v-model="query"
                type="search"
                :placeholder="$t('components.EditCropfields.search-placeholder')"
              ></b-form-input>
            </b-input-group>

            <!-- If the search query matches a farm, display "add all cropfields from farm" button -->
            <b-button
              size="sm"
              v-if="farmResult"
              @click="addFarmCropfields(farmResult.id)"
              class="mb-2 button-default button-icon whitespace-normal"
            >
              <img :src="plusIcon" alt="icon more action" />
              {{ $t("components.EditCropfields.add-from") }} "{{ farmResult.name }}"
            </b-button>

            <!-- If there is at least 1 cropfield in the list, display "add all cropfields" button -->
            <b-button
              size="sm"
              v-if="cropfieldResults.length > 0"
              @click="addAllCropfieldsDisplayed"
              class="mb-2 button-default button-icon whitespace-normal"
            >
              <img :src="plusIcon" alt="icon more action" />
              {{ $t("components.EditCropfields.add-all-cropfields-listed") }}
            </b-button>

            <!-- No result text -->
            <div v-if="cropfields == 0 || cropfieldResults.length == 0" class="text-dark-grey mt-4 text-center">
              {{ $t("components.EditCropfields.no-result") }}
            </div>

            <!-- List of results -->
            <div class="flex-grow search-panel-results">
              <b-overlay :show="!cropfieldsLoaded" rounded="sm">
                <b-list-group>
                  <b-list-group-item
                    class="mb-0"
                    v-for="cropfield in cropfieldResults"
                    @click="zoomOnCropfield(cropfield.id)"
                    :disabled="isCropfieldSelected(cropfield.id)"
                    :key="cropfield.id"
                  >
                    <b-row>
                      <b-col cols="10" class="cursor-pointer">
                        <h4 class="mb-1">{{ cropfield.name }} ({{ cropfield.surface }} ha)</h4>
                        <span class="text-dark-grey text-sm">{{ cropfield.farm_name }} <span v-if="cropfield.crop">· {{ cropfield.crop_name }}</span></span>
                      </b-col>
                      <b-col cols="2" class="d-flex justify-content-end align-items-center">
                        <!-- Button to add a cropfield -->
                        <b-button
                          type="button"
                          @click="addCropfield(cropfield.id, true, true)"
                          class="button-icon button-default"
                        >
                          <img :src="plusIcon" class="mx-2" alt="icon more action" />
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-list-group-item>
                </b-list-group>
              </b-overlay>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import _ from "lodash";
import Fuse from "fuse.js";

import { mapGetters, mapActions, mapMutations } from "vuex";
import delIcon from "@/assets/images/delete.svg";
import chevronDownIcon from "@/assets/images/chevron-down.svg";
import chevronUpIcon from "@/assets/images/chevron-up.svg";
import plusIcon from "@/assets/images/plus-sign-to-add.svg";
import searchIcon from "@/assets/images/filter-search.svg";

import DetailUpdateMixin from "@/mixins/DetailUpdateMixin";
import CropfieldsMap from "@/views/cropfields/CropfieldsMap.vue";

import { EventBus } from "@/eventBus.js";

import NumericInput from "@/components/commons/NumericInput.vue";
import CropDropdown from '@/components/commons/CropDropdown.vue';
import EditCrop from "@/components/commons/details/edit/EditCrop.vue";

import EditCropfieldInputs from "@/components/commons/details/edit/EditCropfieldInputs.vue";

export default {
  mixins: [DetailUpdateMixin],
  components: {
    CropfieldsMap,
    NumericInput,
    CropDropdown,
    EditCropfieldInputs,
    EditCrop
  },
  data() {
    return {
      // search query
      query: "",
      // icons
      delIcon,
      searchIcon,
      plusIcon,
      chevronDownIcon,
      chevronUpIcon,
      // selected crop
      crop: {},
      // over which cropfield in details
      detailCropfield: null,
      keepDropdownOpen: null,
      defineSpecificDoses: false
    };
  },
  props: {
    closable: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    // used to synchronise list and map actions
    EventBus.$on("onMapCheck", this.addCropfield);
    EventBus.$on("onMapUncheck", this.deleteCropfield);

    // display modal on load
    this.$refs["modal"].show();

    // En modification de tournée avec doses spécifiques
    // la case permettant d'afficher les doses spécifiques est cochée par défaut
    this.defineSpecificDoses = !this.isGenericTour
  },
  beforeDestroy() {
    EventBus.$off("onMapCheck", this.addCropfield);
    EventBus.$off("onMapUncheck", this.deleteCropfield);
  },
  computed: {
    ...mapGetters({
      cropfields: "cropfield/entriesWithoutSubset",
      cropfieldsLoaded: "cropfield/cropfieldsLoaded",
      farms: "farm/farms",
      crops: "crop/crops",
      tourCrops: "tour/crops",
      currentStockStore: "stockStores/currentStockStore"
    }),
    entry() {
      return this.hasEditedEntry ? this.currentEditedEntry : this.currentEntry;
    },
    selectedCropfields() {
      return this.entry.cropFields?.map((c) => c.cropField) || [];
    },
    hasEditedEntry() {
      return this.currentEditedEntry.cropFields;
    },
    // list of cropfields returned from search, possibly empty list
    cropfieldResults() {
      if (this.query == "") return this.cropfields;

      const fuse = new Fuse(this.cropfields, {
        keys: ["name", "farm_name", "crop_name"],
        // see https://fusejs.io/api/options.html#basic-options
        ignoreLocation: true,
        threshold: 0,
      });

      return fuse.search(this.query).map((i) => i.item);
    },
    // the farm returned from search, possibly null
    farmResult() {
      if (this.query.length < 3) return;

      const fuse = new Fuse(this.farms, {
        keys: ["name"],
        // see https://fusejs.io/api/options.html#basic-options
        includeScore: true,
        ignoreLocation: true,
        threshold: 0,
      });

      const results = fuse.search(this.query);

      if (results.length > 0 && results[0].score < 0.35) {
        return results[0].item;
      }
      return;
    },
    totalSurface() {
      return _.round(
        this.entry.cropFields
          ?.map((c) => parseFloat(c.surfaceToWork || c.cropField_surface))
          .reduce((a, b) => a + b, 0),
        2,
      );
    },
    // Teste si une tournée est générique
    isGenericTour() {
      let isGeneric = true;
      this.entry?.cropFields?.forEach(cf => {
        if (this.doesCropfieldHaveSpecificInput(cf)) {
          isGeneric = false;
        }
      })
      return isGeneric
    },
    hasInputs() {
      return this.entry?.inputs?.length > 0;
    },
    hasMoreThanOneCropfield() {
      return this.entry?.cropFields?.length > 1;
    }
  },
  methods: {
    ...mapActions({
      fetchEntries: "cropfield/fetchEntries",
      setFilterByValue: "cropfield/setFilterByValue",
      getInputForm: "tour/getInputForm",
      getAllInputForm: "tour/getAllInputForm"
    }),
    ...mapMutations({
      setSubsetFilter: "cropfield/setSubsetFilter",
    }),
    // on form load, we display the data from currentEntry
    // but if an edit action is done (add / remove cropfield, change surface)
    // then we copy the currentEntry to currentEditedEntry
    copyToEditedEntry() {
      this.$store.dispatch(this.storeEditAction, {
        cropFields: [...(this.currentEntry.cropFields || [])],
      });
    },
    isCropfieldSelected(id) {
      return this.selectedCropfields.includes(id);
    },
    getCropfield(id) {
      return this.cropfields.find((c) => c.id == id);
    },
    // teste si une parcelle a des intrants spécifiques
    doesCropfieldHaveSpecificInput(cropfield) {
      let isSpecific = false;
      let testInput = null;
      if(cropfield?.inputs) {
        cropfield.inputs.forEach(cf_input => {
          testInput = this.entry?.inputs?.find(input => input.inputsStock == cf_input.inputsStock && input.dose != cf_input.dose)
          if (testInput) {
            isSpecific = true;
          }
        })
      }
      return isSpecific;
    },
    // teste si une parcelle a un volume de bouillie spécifique
    doesCropfieldHaveSpecificMixtureVolume(cropfield) {
      return cropfield.mixtureVolume && cropfield.mixtureVolume != this.entry.mixtureVolume;
    },

    // teste si une parcelle a des intrants spécifiques ou un volume spécifique
    isCropfieldSpecific(cropfield) {
      return this.doesCropfieldHaveSpecificInput(cropfield) || this.doesCropfieldHaveSpecificMixtureVolume(cropfield);
    },
    // add all the cropfields from a farm
    addFarmCropfields(farm_id) {
      const cropfields = this.cropfields.filter((c) => c.farm == farm_id);

      cropfields.forEach((c) => this.addCropfield(c.id, true, false));
    },
    // Add all cropfields displayed in the list
    // It can be the result of either a research or a filter on crop
    addAllCropfieldsDisplayed() {
      this.cropfieldResults.forEach(c => this.addCropfield(c.id, true, false))
    },
    // add a cropfield
    addCropfield(id, checkOnMap = false, zoomOnMap = false) {
      // already added ? return
      if (this.isCropfieldSelected(id)) return;
      // currentEditedEntry still empty ? initalize it
      if (!this.hasEditedEntry) this.copyToEditedEntry();

      const cropfield = this.getCropfield(id);

      if (!cropfield) return;

      // if it is a new crop, getInputForm
      if (this.tourCrops.find(crop => crop.id == cropfield.crop) == undefined) {
        this.getInputForm(cropfield.crop);
      }

      const cropfields = [
        ...(this.entry.cropFields || []),
        {
          cropField: cropfield.id,
          cropField_name: cropfield.name,
          cropField_surface: cropfield.surface,
          farm_name: cropfield.farm_name,
          crop: cropfield.crop,
          crop_name: cropfield.crop_name
        },
      ];

      this.$store.dispatch(this.storeEditAction, { cropFields: cropfields });

      // synchronize with map
      if (checkOnMap) {
        EventBus.$emit("checkOnMap", id);
      }
      if (zoomOnMap) {
        EventBus.$emit("zoomOnCropfield", id);
      }
    },
    zoomOnCropfield(id) {
      EventBus.$emit("zoomOnCropfield", id);
    },
    setCropfieldSurface(id, surface) {
      // currentEditedEntry still empty ? initalize it
      if (!this.hasEditedEntry) this.copyToEditedEntry();

      const cropfields = JSON.parse(JSON.stringify(this.entry.cropFields));

      cropfields.find((c) => c.cropField == id).surfaceToWork = surface;

      this.$store.dispatch(this.storeEditAction, { cropFields: cropfields });
    },
    deleteCropfield(id, uncheckOnMap = false) {
      // currentEditedEntry still empty ? initalize it
      if (!this.hasEditedEntry) this.copyToEditedEntry();

      // already deleted ? return
      if (!this.isCropfieldSelected(id)) return;

      let cropfields = JSON.parse(JSON.stringify(this.entry.cropFields));

      cropfields = cropfields.filter((c) => c.cropField != id);

      this.$store.dispatch(this.storeEditAction, { cropFields: cropfields });

      if (uncheckOnMap) EventBus.$emit("checkOnMap", id);
    },
    onMapReady() {
      // on map first load, check existing selected cropfield on map
      this.selectedCropfields.forEach((c) => {
        EventBus.$emit("checkOnMap", c);
      });
    },
    /**
     * will
     */
    async onSelectCrop(crop) {
      this.crop = crop && { id: crop.id, name: crop.name };
    },
    onInputModalClose() {
      setTimeout(() => {
        this.keepDropdownOpen = null
      }, 1500)
    },
    onClickCropfieldCard(index) {
      if(this.keepDropdownOpen == null)
        this.keepDropdownOpen = index;
      else
        this.keepDropdownOpen = null;
    }
  },
  watch: {
    // every time we make a search
    // we update the subset filter in the cropfields module
    // according to the search results
    query: _.debounce(function () {
      this.setSubsetFilter(this.cropfieldResults.map((c) => c.id));
      EventBus.$emit("plotFields");
    }, 200),
    tourCrops(newValue, oldValue) {
      if(_.isEqual(newValue, oldValue)) return

      if(newValue.length < oldValue.length) {
        this.getAllInputForm()
      }

      // update crop value for the edited tour
      // if we have only one crop in the selected cropfields
      // then fill with this crop id
      // otherwise set to null
      this.$store.dispatch(this.storeEditAction, {
        crop: newValue.length == 1 ? newValue[0].id : null
      });
    },
    defineSpecificDoses: {
      handler() {
        // Si on décoche la case "définir des doses spécifiques, on supprime toutes les doses spécifiques préalablement définies"
        if (!this.defineSpecificDoses && this.currentEditedEntry.cropFields) {
          this.$store.commit("tour/resetSpecificInputs");
        }
      }
    }
  },
};
</script>

<style lang="scss">
#cropfield-picker-modal {
  width: calc(100vw - 27vw);
  margin-left: 0 !important;
  margin-top: 105px !important;
}

@media screen and (min-width: 1444px) {
  #cropfield-picker-modal {
    width: calc(100vw - 395px) !important;
  }
}

#cropfield-picker-modal___BV_modal_outer_ {
  z-index: 50 !important;
}

#cropfield-picker-modal .map,
#cropfield-picker-modal .search-panel {
  height: calc(100vh - 230px) !important;
}
</style>

<style lang="scss" scoped>
h2 {
  font-size: 1.4rem;
  span {
    font-size: 0.9rem;
    font-style: italic;
  }
}

.displayModal {
  display: block;
}

.search-panel-results {
  overflow-y: scroll;
}

.buttonModalPathActivity {
  display: none;
}

.modal-body {
  padding: 0 !important;
}

.close-button {
  z-index: 10;
  position: absolute;
  right: 0px;
  top: 0px;
  width: 42px;
  height: 41px;
  border-left: 2px solid white;
  border-bottom: 2px solid white;
  padding-top: 4px;
  background: #dc3545;
  opacity: 1;
}

.close-button:hover {
  opacity: 0.75;
}

.close-button:before,
.close-button:after {
  position: absolute;
  left: 19px;
  content: " ";
  height: 32px;
  width: 2px;
  background-color: #ffffff;
}

.close-button:before {
  transform: rotate(45deg);
}

.close-button:after {
  transform: rotate(-45deg);
}

/* display unity on surface input */
.surface-input {
  position: relative;
}
.surface-input::before {
  content: "ha";
  position: absolute;
  width: 20px;
  color: $dark-grey;
  text-align: right;
  right: 30px;
  transform: translateY(-50%);
  top: 50%;
}

.total-area {
  position: absolute;
  top: -0.2rem;
  right: 0;
  font-size: 0.8rem;
}

/*
  On cropfield list, when disabled
  Allow click to zoom, but disable click to add
*/
.list-group-item.disabled {
  pointer-events: all;

  .list-group-item.disabled button {
    pointer-events: none;
  }
}

.cropfield-card {
  overflow: hidden;
}


.cropfield-card-details-scroll {
  transition: all 0.8s;

  max-height: 0;

  overflow: hidden;

  padding: 0;
}

.cropfield-card-details-fade {
  opacity: 0;
  transition: 0.4s;
}

.cropfield-card.hovered .cropfield-card-details-scroll {
  max-height: 1000px;
  height: auto;
}

.cropfield-card {
  transition: 0.4s;
  border-radius: 4px;
  outline: 2px solid rgba(255, 255, 255, 0);
  outline-offset: 2px;
}

.cropfield-card.hovered {
  outline: 2px solid $grey;
}

.cropfield-card.hovered .cropfield-card-details-fade {
  opacity: 1;
}

.specific-cropfield {
  background-color: #F09D0B3F;
};

</style>
